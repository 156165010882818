@use '../variables' as v;

.contact{
    margin: 5rem 10rem;

    @media screen and (max-width: v.$tablet) {
        margin: 5rem 0;
    }

    &__container {
        display: flex;
        align-items: center;
        gap: 1.875rem;

        @media screen and (max-width: v.$tablet) {
            flex-direction: column;
            height: auto;
        }
    }

    &__img{
        &__container{
            min-width: 23.25rem;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: v.$mobile) {
                width: 100%;
            }

            img{
                width: 100%;
                height: 100%;
            }
        }
    }

    &__data{
        padding: 0.5rem 0;
        height: 100%;
        gap: 0.875rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: v.$tablet) {
            gap: 1rem;
            padding: 0.5rem 1rem;
        }

        &__title{
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
        }

        &__content{
            line-height: 24px;
            font-size: 16px;

            a{
                color: v.$primary-red;
                text-decoration: underline;
            }
        }
        &__row {
            display: flex;
            gap: 0.375rem;
            font-weight: bold;
            align-items: center;

            a {
                display: none;
                color: v.$primary-red;
                font-weight: bold;

                @media screen and (max-width: v.$mobile) {
                    display: block;
                }
            }

            span {
                @media screen and (max-width: v.$mobile) {
                    display: none;
                }
            }
        }

        &__column {
            line-height: 24px;
            font-size: 16px;
        }
    }
}