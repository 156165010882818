* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100vh;
    width: 100vw;
    font-family: "Avenir Next LT W01 Medium", Helvetica, Arial, sans-serif;
    font-weight: 500;
    line-height: 22px;
    color: #25282A;
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary{
    display:block
}

audio,canvas,progress,video{
    display:inline-block;vertical-align:baseline
}

a {
    text-decoration:none;
    color:inherit;
}

li{
    list-style-type:none
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
}


