@use '../variables' as v;

.z-link {
    padding: clamp(0.2rem, 1vh, 0.8rem) clamp(0.4rem, 1vw, 1.2rem);
    background-color: v.$primary-red;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: v.$tablet) {
        padding: clamp(0rem, 5vw, 0.6rem);
        font-size: clamp(10px, 3.1vw, 16px);
    }
}

.col {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
}

.active {
    display: flex !important;
}

.rotated {
    transform: rotate(180deg);
}