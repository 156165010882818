@use '../variables' as v;

.sub {
    margin: 0 10rem;
    padding: 5rem 0;
    border-top: 1px solid lightgray;

    @media screen and (max-width: v.$tablet) {
        margin: 0 1.5rem;
        padding: 2rem 0;
    }

    p {
        font-size: 16px;
        line-height: 24px;
    }
}