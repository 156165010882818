@use '../variables' as variables;

.description {
    margin-top: 3.5rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap:1.7rem;
    align-items: center;

    &__title{
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
    }

    &__content{
        text-align: center;
        font-size: 16px;
        line-height: 24px;
    }
}