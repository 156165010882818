@use '../variables' as variables;

.chars {
    display: flex; 
    justify-content: center;
    align-items: center;
    margin: 1.8rem 0;

    @media screen and (max-width: variables.$mobile) {
        padding: 0 2.2rem;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; 
        gap: 1.5rem;

        @media screen and (max-width: variables.$tablet) {
            justify-content: space-between;
        }
    
    }

    &__element {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 24px;
        font-size: 16px;
        gap: 1rem;
        padding: 0.2rem;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        height: auto;

        @media screen and (max-width: variables.$tablet) {
            width: 220px;
        }


        @media screen and (max-width: variables.$mobile) {
            margin-left: 0;
            width: auto;
        }

        img {
            width: 40px;
            height: 40px;
        }

        p {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
    }
}
