@use '../variables' as v;

$background-color: #F0F2F8;

.column {
    padding: 1rem 10rem;

    @media screen and (max-width: v.$tablet) {
        padding: 1rem;
    }
}

.main {
   margin-top: 63px;
   font-family: v.$poppins;

    & .hero {
    width: 100%;
    height: auto;
    background-color: $background-color;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: v.$tablet) {
        height: auto; 
    }
    @media screen and (max-width: v.$mobile) {
        min-height: 400px;
    }

        &__container  {
            height: 100%;
            position: relative;
            width: 70%;
            display: flex;
            align-items: flex-end;

            @media screen and (max-width: v.$tablet) {
                height: 100%;
                width: 100%;
            }

            @media screen and (max-width: v.$mobile) {
                min-height: 400px;
                width: 100%;
                overflow: hidden;
            }

            &__left{
                width: 50%;
                height: 100%;
                margin-top: auto;
                margin-bottom: auto;
                padding: clamp(0rem, 3vw, 2rem);
                display: flex;
                position: relative;
                transform: translateX(15%);
                z-index: 2;

                @media screen and (max-width: v.$mobile) {
                    padding: 0;
                }

                @media screen and (max-width: v.$mobile) {
                    width: 100%;
                    overflow: hidden;
                    transform: translateX(-20px);
                }
                .sphere{
                    width: 100%;
                    aspect-ratio: 1/1;
                    min-width: 300px;
                    background-color: white;
                    border-radius: 50%;
                    align-self: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 1.1rem;
                    padding: 0.5rem;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                    @media screen and (max-width: v.$mobile) {
                        box-shadow: none;
                        height: 300px;
                        width: 300px;
                        padding: 0;
                        margin: 0;
                    }

                    h1 {
                        font-size: clamp(12px, 2vw, 36px);;
                        font-family: v.$docMorris;
                        padding: 0 1rem;
                        text-align: center;
                        line-height: clamp(28px, 3vw, 48px);

                        @media screen and (max-width: v.$tablet) {
                            padding: 0 2rem;
                            font-size: clamp(12px, 3.5vw, v.$font-small);
                            line-height: 28px;
                        }

                        @media screen and (max-width: v.$mobile) {
                            font-size: clamp(20px, 4vw, v.$font-small);
                            line-height: clamp(26px, 3vw, 1.5rem);
                        }
                    }
                }
            }

            &__right{
                position: relative;
                transform: translateX(-15%);
                display: flex;
                align-items: center;
                justify-content: end;
                width: 50%;
                height: 100%;
                z-index: 3;

                @media screen and (max-width: v.$mobile) {
                    transform: translateX(165px);
                    position: absolute;
                    width: 100%;
                    overflow: hidden;
                }

                img {
                    width: 100%; 
                    height: auto;
                    max-width: 100%;
                }
            }
        }
    }
}