@use '../variables' as v;

$background-color: #F0F2F8;

.services{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;

    @media screen and (max-width: v.$tablet) {
        flex-direction: column;
    }

    &__container{
        width: 50%;
        background-color: $background-color;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap:1.5rem;

        @media screen and (max-width: v.$tablet) {
            width: 100%;
        }
    }

    &__title{
        text-align: center;
        font-size: v.$font-base;
        line-height: v.$spacing-small;
    }

    &__list{
        margin-left:1rem;
    }

    &__list li{
        list-style-type: decimal;
        margin: 5px 0;

    }

    &__linkContainer{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
    }

    &__link{
        @media screen and (max-width: v.$tablet) {
            width: 100%;
        }
    }
    &__link span{
        margin-right: 5px;
    }

}