// Breakpoints
$mobile: 460px;
$tablet: 768px;
$desktop: 1440px;

// Font families
$docMorris: "DocMorris", Helvetica, Arial, sans-serif;
$avenirNextMedium: "Avenir Next LT W01 Medium", Helvetica, Arial, sans-serif;
$poppins: "Poppins", Helvetica, Arial, sans-serif;

// Font sizes
$font-big: 36px;
$font-medium: 24px;
$font-small: 22px;
$font-base: 1.125rem;
$font-xSmall: 1.125rem;

// Font weights

// Spacing
$spacing-small: 1.375rem;

// Colors
$primary-red: #E30613;
$primary-gray: #F0F2F8;
$secondary-gray: #565A61;
$tertiary-gray: #25282a;