@font-face {
    font-family: "Poppins";
    src: url("../../sites/default/font/03_Poppins-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Poppins";
    src: url("../../sites/default/font/03_Poppins-Italic.otf") format("opentype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Poppins";
    src: url("../../sites/default/font/02_Poppins-SemiBold.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Poppins";
    src: url("../../sites/default/font/02_Poppins-SemiBoldItalic.otf") format("opentype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Avenir Next LT W01 Bold";
    src: url("https://www.zurrose.ch/themes/custom/zurrose/assets/fonts/721281/14c73713-e4df-4dba-933b-057feeac8dd1.woff2") format("woff2"),
         url("https://www.zurrose.ch/themes/custom/zurrose/assets/fonts/721281/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff") format("woff");
}
  
@font-face {
    font-family: "Avenir Next LT W01 Medium";
    src: url("https://www.zurrose.ch/themes/custom/zurrose/assets/fonts/721275/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2") format("woff2"),
         url("https://www.zurrose.ch/themes/custom/zurrose/assets/fonts/721275/f26faddb-86cc-4477-a253-1e1287684336.woff") format("woff");
}

@font-face {
    font-family: "DocMorris";
    src: url("../../sites/default/font/DocMorris-Regular.woff2") format("woff2"),
    url("../../sites/default/font/DocMorris-Regular.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "DocMorris";
    src: url("../../sites/default/font/DocMorris-Italic.woff2") format("woff2"),
    url("../../sites/default/font/DocMorris-Italic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }