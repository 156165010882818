@use '../variables' as v;

.footer {
    &__top{
        display: flex;
        background-color: v.$primary-gray;
        justify-content: space-between;
        padding: 2.5rem;

        @media screen and (max-width: v.$tablet) {
            flex-direction: column;
            gap: 1.5rem;
            padding: 1.875rem;
        }

        h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }

        div:first-child p, div:nth-child(2) span{
           font-size: 24px;
           font-weight: 30px;
           font-weight: 500;
        }

        div:nth-child(3) div {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    &__middle{
        display: flex;
        padding: 2.5rem;
        background-color: v.$secondary-gray;
        color: white;
        justify-content: space-between;

        @media screen and (max-width: v.$tablet) {
            flex-direction: column;
            gap: 1.5rem;
            width: 100%;
        }

        h5 {
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;

            @media screen and (max-width: v.$tablet) {
                padding: 1rem 0;
                border-bottom: 1px solid v.$primary-gray;
            }

            
        }

        .arrow {
            display: none;

            @media screen and (max-width: v.$tablet) {
                display: block;
            }
        }

        .col{
            width: 25%;

            @media screen and (max-width: v.$tablet) {
                width: 100%;
                
            }
        }

        .collapsible{
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        .content{
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @media screen and (max-width: v.$tablet) {
                display: none;
            }
        }
        
        a{
            font-size: 14px;
            line-height: 20px;
        }
    }

    &__bottom{
        display: flex;
        background-color: v.$tertiary-gray;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap:2rem;
        color: white;
        padding: 1.5rem 1rem;
        font-size: 14px;

        span{
            color: v.$secondary-gray;
        }
    }
}