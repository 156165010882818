@use '../variables' as v;

.advantages {
    display: flex;
    flex-direction: column;
    background-color: v.$primary-gray;
    padding: 48px 24px;
    margin: 0 10rem;

    @media screen and (max-width: v.$tablet) {
        margin: 0;
    }

    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:48px;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__title{
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
    }

    &__list{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        row-gap: 32px;

        &__element{
            width: 340px;
            display: flex;
            margin: 5px auto;
            gap: 12px;

            @media screen and (max-width: v.$tablet) {
               width: auto;
               margin-left: 0;
            }

            h4 {
                font-weight: semibold;
            }

            h4, p {
                font-size: 16px;
                line-height: 24px;
            }

            img {
                width: 40px;
                height: 40px;
            }
        }
    }
}