@use '../variables' as v;

.promotion {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5rem auto;

    &__img {
        min-height: 100%;
        min-width: 100%;

        &__container{
            margin:auto;
            width: 370px;
            height: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        gap: 1.875rem;

        @media screen and (max-width: v.$tablet) {
            flex-direction: column;
        }
    }

    &__title{
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
    }

    &__text{
        font-size: 16px;
        line-height: 24px;

        &__container{
            max-width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.125rem;

            img {
                max-height: 28px;
                max-width: 100px;
            }
        }
    }
}