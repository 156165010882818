@use '../variables' as variables;

$background-color: #fff;
$border-color: #dadada;

.header{
    position: fixed;
    top: 0;
    width: 100%;
    height: 4rem;
    z-index: 100;

    &__container{
        height: 100%;
        width: 100%;
        background-color: $background-color;
        max-width: 100vw;
        border-bottom: 1px solid $border-color;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 10vw;

        &__left {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 150px;

            & a {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & img {
                height: 100%;
                width: 100%;
            }
        }

        &__right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 1.5rem;
            height: 100%;
            width: 300px;

            .login__a{
                display: flex;
                height: 100%;
                align-items: center;
                color: inherit;
                text-decoration: none;
                gap: 1rem;

                & span {
                    @media screen and (max-width: variables.$tablet) {
                        display: none;
                    }
                }
            }

            .lang_selector{
                border: none;
                padding: 0 auto;
                width: 3.25rem;
                outline: none;
            }

            @media screen and (max-width: variables.$mobile) {
                font-size: small;
            }
        }
    }
}
